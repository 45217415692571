body {
  background-image:  url(img/bg.png) ;
  background-attachment:fixed;
  background-repeat: no-repeat;
  background-position: center center ;
  background-size: cover;
  font-family: $font-base;

  &:lang(ar) {
    direction: rtl;
    font-family: $font-arabic !important;
    font-weight: 400;
  }
}

.rightf{
  float: right !important;
}
.page-styling {
  background: #ffffff;
}

.container{
  background: #ffffff;
  @media (max-width: 767px){
    margin: 0 20px !important;
  }
}

img{
  max-width: 100%;
  vertical-align: middle;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: 700;

}
p {
  font-size: 20px;
  color: #9f9f9f;
  text-align: justify;
  margin-bottom:10px;
}
hr {
  border-color: #acacac;
}



.hameid-loader-overlay {
  width: 100%;
  height: 100%;
  background: url('img/ajax-loader1.gif') center no-repeat #ffffff;
  z-index: 99999;
  position: fixed;
}




//---------------------map-----------------------------------//
#map{
  margin-bottom: 10px;
}
#map_wrapper {
  height: 400px;
  direction: ltr;
}

#map_canvas {
  width: 100%;
  height: 100%;
}
.gm-style .gm-style-iw{
  padding: 10px !important;
}
////////////////////////////////////////////
.location{
  margin: 50px 20px;
  .bottom-blue{
    a{
      color: #ffffff;
      background: #337ab7;
      padding: 10px;
      cursor: pointer;
      margin: 10px auto;

    }
  }

  .bottom-red{
    a{
      color: #ffffff;
      background: #e20b17;
      padding: 10px;
      cursor: pointer;
      margin: 10px auto;

    }
  }


  p{
    font-size: 15px;
    margin-bottom: 0;
  }
}