.calendar-top{
  background: #eaa830;
  margin: 20px auto;
  padding: 5px 30px 25px 30px;
 
  h3{
    font-size: 18px;
    border-bottom: 1px solid #fff;
    color: #fff;
    line-height: 35px;
  }
 
  p {
    color: #ffffff;
    margin: 5px;
    font-size: 20px;
  }

  .bmore {
    margin:60px auto 20px  ;
    a{
      background: #0C80C3;
      padding:10px 20px;
      color: #ffffff;
      &:hover{
        @include transition-all(0.2s ease-in-out);
        background: #404041;
      }
    }
  }
}

.calendar-option{
  border-right: 1px solid #e1e2e2;
  padding: 20px;

  a{
    color: #78787b;
    background:inherit;
    &:hover{
      @include transition-all(0.2s ease-in-out);
      color: #fff;
    }
  }
  .option-1{
    background: #80bad1;
    padding:10px;
    margin: 10px;


  }.option-2  {
     background: #b3b56a;
       padding:10px;
       margin: 10px;

   }
  .option-3 {
    background: #bababb;
    padding:10px;
    margin: 10px;

  }
   
}


.calendar-glance{
  border-right: 1px solid #e1e2e2;
  padding: 20px;

  a{
    color: #78787b;
    background:inherit;
    display: block;
    padding: 10px 20px;
    &:hover{
      @include transition-all(0.2s ease-in-out);
      color: #000;
    }
  }
  .option-4 {
    background: #e1e2e2;
    padding:10px;
    margin: 10px;
    color: #78787b;
    font-size: 16px;
    font-weight: 600;
  }

}

.calendar{
  h4{
    margin-top: 0;
    font-size: 20px;
    font-weight: 500;
    color: #0c80b0;
  }
  .month{
    border-bottom: 5px solid #b3baa4;
    border-top: 5px solid #b3baa4;
    padding: 75px 52px;
    font-size: 17px;
    color: #0c80b0;
  }
}








//////////////////////////////////////////

.multi-item-carousel{
  .carousel-inner{
    > .item{
      transition: 500ms ease-in-out left;
    }
    .active{
      &.left{
        left:-33%;
      }
      &.right{
        left:33%;
      }
    }
    .next{
      left: 33%;
    }
    .prev{
      left: -33%;
    }
    @media all and (transform-3d), (-webkit-transform-3d) {
      > .item{
        // use your favourite prefixer here
        transition: 500ms ease-in-out left;
        transition: 500ms ease-in-out all;
        backface-visibility: visible;
        transform: none!important;
      }
    }
  }
  .carouse-control{
    &.left, &.right{
      background-image: none;
    }
  }
}

.box-calendar{
  width: 190px;
  height: 190px;
  background: #b3b56a;

  span{
    padding: 1px 5px; 
    color: #fff !important;
  }
  .date{
    float:left;
    font-weight: 700;
    color: #fff;
    font-size: 35px;
    padding: 1px 5px;
  }
  .time{
    float: right;
    color: #fff;
    font-size: 15px;
    padding: 20px 4px;
    direction:ltr ;
  }

  .title{
    display: table-cell;
    padding: 10px 15px;
    font-size: 17px;
    color: #78787b;
    direction: inherit;
  }
}
