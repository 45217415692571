
@font-face {
  font-family: 'MyriadPro';
  src: url('fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),  url('fonts/MyriadPro-Regular.otf')  format('opentype'),
  url('fonts/MyriadPro-Regular.woff') format('woff'), url('fonts/MyriadPro-Regular.ttf')  format('truetype'), url('fonts/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}




@font-face {
  font-family: 'DINNextLTW23';
  src: url('fonts/DINNextLTW23-Black.eot?#iefix') format('embedded-opentype'),  url('fonts/DINNextLTW23-Black.woff') format('woff'), url('fonts/DINNextLTW23-Black.ttf')  format('truetype'), url('fonts/DINNextLTW23-Black.svg#DINNextLTW23-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'DINNextLTW23';
  src: url('fonts/DINNextLTW23-Bold.eot?#iefix') format('embedded-opentype'),  url('fonts/DINNextLTW23-Bold.woff') format('woff'), url('fonts/DINNextLTW23-Bold.ttf')  format('truetype'), url('fonts/DINNextLTW23-Bold.svg#DINNextLTW23-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DINNextLTW23';
  src: url('fonts/DINNextLTW23-Heavy.eot?#iefix') format('embedded-opentype'),  url('fonts/DINNextLTW23-Heavy.woff') format('woff'), url('fonts/DINNextLTW23-Heavy.ttf')  format('truetype'), url('fonts/DINNextLTW23-Heavy.svg#DINNextLTW23-Heavy') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'DINNextLTW2';
  src: url('fonts/DINNextLTW23-Light.eot?#iefix') format('embedded-opentype'),  url('fonts/DINNextLTW23-Light.woff') format('woff'), url('fonts/DINNextLTW23-Light.ttf')  format('truetype'), url('fonts/DINNextLTW23-Light.svg#DINNextLTW23-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'DINNextLTW23';
  src: url('fonts/DINNextLTW23-Medium.eot?#iefix') format('embedded-opentype'),  url('fonts/DINNextLTW23-Medium.woff') format('woff'), url('fonts/DINNextLTW23-Medium.ttf')  format('truetype'), url('fonts/DINNextLTW23-Medium.svg#DINNextLTW23-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DINNextLTW23';
  src: url('fonts/DINNextLTW23-Regular.eot?#iefix') format('embedded-opentype'),  url('fonts/DINNextLTW23-Regular.woff') format('woff'), url('fonts/DINNextLTW23-Regular.ttf')  format('truetype'), url('fonts/DINNextLTW23-Regular.svg#DINNextLTW23-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DINNextLTW23';
  src: url('fonts/DINNextLTW23-UltraLight.eot?#iefix') format('embedded-opentype'),  url('fonts/DINNextLTW23-UltraLight.woff') format('woff'), url('fonts/DINNextLTW23-UltraLight.ttf')  format('truetype'), url('fonts/DINNextLTW23-UltraLight.svg#DINNextLTW23-UltraLight') format('svg');
  font-weight: 200;
  font-style: normal;
}
