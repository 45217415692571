/* ------------------------------------------------------------ *\
	General
\* ------------------------------------------------------------ */

.header-box{
  position: relative;
  max-width: 370px;
  float: none;
  margin: 20px auto;
  .box-img{
        img{
          display: inline-block;
          max-width: 100%;
        }
  }
  .box-hover{
    position: absolute;
    bottom: -3px;
    #img2{
      display: none;
    }

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: #000;
      transition: width .3s;
    }

  .box-title{
    left: 50px;
    right: 50px;
    position: absolute;
    bottom: 60px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-align: center;

    @media (min-width: $breakpoint-425) and (max-width: $breakpoint-768) {
      bottom: 40px;
      color: #fff;
      font-size: 14px;
    }
  }
  }

  a:hover{
    #img2{
      display: block;
    }
    #img1{
      display: none;
    }
    .box-img {
      opacity: 0.7;
      @include transition-all(0.3s);
    }
    .box-title{color: #000;}
    .box-hover::after{
      width: 100%;

    }
  }

}

.search{
  background: #D3D1D7;
  margin: 20px auto;
  padding: 15px 30px;
  h3{
    color: #0C80C3;
    text-transform: none;
    margin: 5px;
    text-align: center;

  }
  img{
    width:60px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .search-form{
    .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;

    }

    .btns{
      background: #0C80C3;
      color: #ffffff;
      padding: 5px 30px;
      border-radius: 0;
      &:hover{
        background: #404041;
        @include transition-all(0.2s ease-in-out);
      }
    }
    .input-label{
      width: 400px;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;

      @media (max-width: $breakpoint-768) {
        width: 295px;
        margin-left: auto;
        margin-right: auto;

      }
      @media (max-width: $breakpoint-425) {
        width: 100px;
      }
    }
  }
}

.about{
  h2{
    font-size:26px;
    text-transform:none;
    color: #404041;
    padding-bottom: 10px;
  }
  p{
    color: #78787B;
    font-size: 14px;
    text-align: justify;
    padding-right: 100px;
    &:lang(ar) {
      padding-left: 100px;
      padding-right: 0;
    }
  }
  .bmore {
    margin:20px auto  ;
    a{
      background: #0C80C3;
      padding:10px 20px;
      color: #ffffff;
      &:hover{
        @include transition-all(0.2s ease-in-out);
        background: #404041;
      }
    }
  }

  .quote-about{
    background: #EA2B7B;
    margin: 20px auto;
    padding: 10px 170px;
    @media (max-width: $breakpoint-768) {
      padding: 10px 90px;
    }
    @media (max-width: $breakpoint-425) {
      padding: 10px 10px;
    }
    p {
      color: #ffffff;
      font-size: 20px;
      text-align: center;
      padding: 0;
      margin:0;
    }
    span.author{
      font-size: 14px;
      font-style: italic;
      color: white;
      float: right;
      padding: 0 170px;
        &:lang(ar) {
        float: left;
        }

      @media (max-width: $breakpoint-768) {
        left: 70px;
        padding: 0 0 ;
        top: 60px;
      }

    }

    &:hover{
      opacity:0.8;
      @include transition-all(0.2s ease-in-out);
    }

  }

  ol.carousel-indicators{
    top:68px;
  }

}


.small-audit-baby {
    width: 100%;
    padding: 157px 0;
    position: relative;
    margin-top: -6px;
}
 .rose{
    background: #d72b7b;
}
.green{
 background:#87be91;
}
.orange{
 background:#eaa830;
}
.darkblue{
 background:#0c80b0;
}
.red{
 background:#da312a;
}
 .green2{
 background:#b3b56a;
}



.academic{
  background: #D3D1D7;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;

  .academic-box{
    margin: 10px auto;
    .academic-img{
      position: relative;
    }
    .academic-title{
      text-align: center;
      top: 120px;
      right: 15px;
      padding: 20px;
      left: 15px;
      //background: rgba(0, 0, 0, 0.49);
      position: absolute;
      font-size: 20px;
      color: #ffffff;
      cursor: pointer;
      &:hover{
        //background: rgba(197, 204, 198, 0.77);
        color: #000000;
        font-size: 26px;
        @include transition-all(0.2s ease-in-out);
        text-decoration: underline !important;
        @media (max-width: $breakpoint-768) {
          font-size: 15px;
        }
      }
      @media (max-width: $breakpoint-991) {
        padding: 0;
      }
      @media (max-width: $breakpoint-768) {
        top: 40px;
        font-size: 15px;
        padding: 10px;
      }
      @media (max-width: $breakpoint-425) {
        top: 100px;
      }

    }
  }
}

.bottoms{
  background: #78787B;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;

  h2{
    font-size: 25px;
    text-transform: none;
    color: #E7E7E7;
    margin-bottom: 25px;
  }

  .bottoms-container{
    background: #EFEFEF;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 30px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;

    .bottom1{

      padding:5px;
      background: #C6C86A;
      cursor: pointer;
      &:hover{
        @include transition-all(0.2s ease-in-out);
        background:#C8CACB;
        color: #C6C86A;
      }
      @media (max-width: $breakpoint-991) {
        margin: 10px auto;
      }
    }
    .bottom2{
      padding:5px;
      background: #00BBDB;
      cursor: pointer;
      &:hover{
        @include transition-all(0.2s ease-in-out);
        background:#C8CACB;
        color: #00BBDB;
      }
      @media (max-width: $breakpoint-991) {
        margin: 10px auto;
      }
    }
    .bottom3{
      padding:5px;
      background: #EA2B7B;
      cursor: pointer;
      &:hover{
        @include transition-all(0.2s ease-in-out);
        background:#C8CACB;
        color: #EA2B7B;
      }
      @media (max-width: $breakpoint-991) {
        margin: 10px auto;
      }
    }
    .bottom4{
      padding:5px;
      background: #F5851F;
      cursor: pointer;
      &:hover{
        @include transition-all(0.2s ease-in-out);
        background:#C8CACB;
        color: #F5851F;
      }
      @media (max-width: $breakpoint-991) {
        margin: 10px auto;
      }
    }
    .bottom5{
      padding:5px;
      background: #0C80C3;
      cursor: pointer;
      &:hover{
        @include transition-all(0.2s ease-in-out);
        background:#C8CACB;
        color: #0C80C3;
      }
      @media (max-width: $breakpoint-991) {
        margin: 10px auto;
      }
    }
    .bottom6{
      padding:5px;
      background: #ED312A;
      cursor: pointer;
      &:hover{
        @include transition-all(0.2s ease-in-out);
        background:#C8CACB;
        color: #ED312A;
      }
      @media (max-width: $breakpoint-991) {
        margin: 10px auto;
      }
    }

  }
}


.nonviolence {
  background-image: url("img/nonviolence.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin-top: 20px;
  margin-bottom: 20px;

  .nonviolence-container{
    text-align: center;
    margin: 80px 0 98px;
    background: rgba(255, 255, 255, 0.6) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    h1{
      color: #0C80C3;
      font-weight: 500;
      font-size: 80px;
      @media (max-width: $breakpoint-991) {
        font-size: 50px;
      }
      @media (max-width: $breakpoint-768) {
        font-size: 30px;
      }
      @media (max-width: $breakpoint-425) {
        font-size: 20px;
      }
    }
    p{
      padding:0 50px;
      text-align: center;
      color: #404041;
      @media (max-width: $breakpoint-991) {
        font-size: 12px;
      }
      @media (max-width: $breakpoint-768) {
        font-size: 12px;
      }
      @media (max-width: $breakpoint-425) {
        font-size: 10px;
      }
    }
  }
}


.testimonial{
  margin-top: 10px;
  margin-bottom: 10px;
  padding:20px;

  .testimonial-title{
    
    font-size: 35px;
    margin-bottom: 20px;
     &:lang(ar) {
    float: right;
    }
  }
  .testimonial-video{
    text-align: center;

    .border-video{
      border-bottom: 2px solid #3c3c3c;
      border-top: 2px solid #3c3c3c;
      padding: 20px;
    }
 #foo{    border-radius: 5%;
   border: 1px solid;}

  }

  .testimonial-content{
    .head-testimonial{
      font-family: $font-arabic;
      color: #00a8c8;
      font-size: 21px;
      font-weight: 400;
      margin-bottom: 40px;
      border-bottom: 1px solid #eaa830;

    }

    .testimonial-item{

      .videomodal{
        padding:10px;
        text-align: center;
      }
      .profile-circle {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 100px;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;

        background-color: #00B3D3;
        &:hover{
          background: #00D4D4;
          @include transition-all(0.3s);
        }

        .c2{
          background-color: #37AB4A;
          &:hover{
            background: #23DB4A;
            @include transition-all(0.3s);
          }
        }

        p{
          position: absolute;
          font-size: 15px;
          top: 18px;
          right:20px;
          left:20px;
          text-align: center;
          color: #fff;
        }
      }

      .profile-circle2{
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 100px;
        text-align: center;
        margin: 0 auto;
        background-color: #37AB4A;
        &:hover{
          background: #23DB4A;
          @include transition-all(0.3s);
        }

        p{
          position: absolute;
          font-size: 15px;
          top: 18px;
          right:20px;
          left:20px;
          text-align: center;
          color: #fff;
        }
      }

      .profile-content{
        max-height: 240px;
        margin-top: 10px;
        text-align: center;
        border-top:1px solid #78787B;


        .ptext{
          max-height: 20px;


          p{
            text-align: center;
            font-size: 15px;
            font-weight: 400;
          }
        }
        .pmore{
          margin:20px auto;
          color: #00B3D3;
          padding:10px 20px;
          cursor: pointer;
          &:hover{
            @include transition-all(0.2s ease-in-out);
            color: #00D4D4;
            text-decoration: underline;
          }

          a{
            color: #00B3D3;
            padding:10px 20px;
            &:hover{
              @include transition-all(0.2s ease-in-out);
              color: #00D4D4;
            }
          }
        }

        .pmore2{
          margin:20px auto;
          cursor: pointer;

            color: #37AB4A;
            padding:10px 20px;
            &:hover{
              @include transition-all(0.2s ease-in-out);
              color: #23DB4A;
              text-decoration: underline;
            }

          a{
            color: #37AB4A;
            padding:10px 20px;
            &:hover{
              @include transition-all(0.2s ease-in-out);
              color: #23DB4A;
              text-decoration: underline;
            }

          }

        }


      }
    }
  }

}

.media{
  background: #E7E7E7;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  h2{
    font-size: 25px;
    text-transform: none;
    color: #404041;
    margin-bottom: 5px;
  }

  #gallery{
    background: #000 !important;
    border: 4px solid #000 !important;
  }



  .nav-tabs>li>a{
    background: #0e2346;
    color: #fff;
    border:0;
    border-radius: 0;
    margin: 30px 10px;

  }

  .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
    background:#ed801f ;
    color: #fff;
    border-radius: 0;
    border:0;
  }
.nav-tabs>li {
    font-size: 16px;
    &:lang(ar) {
      float: right;
    }

  }
   .nav-tabs>li>a:hover{
    background:#ed801f ;
  }

}


.contact{
  background: #CBC9CF;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  .contact-info{
    padding: 50px 100px;
    @media (max-width: $breakpoint-768) {
      padding: 0 10px;
    }
    h2{
      font-size: 25px;
      text-transform: none;
      color: #0D80BF;
      margin-bottom: 5px;
      border-bottom: 2px solid;
      line-height: 40px;
    }
    p{
      font-size: 16px;
      text-align: justify;
      margin-bottom: 0;
      color: #5E5F5E;
      a{
        color: #5E5F5E;
      }
    }
  }

  .form-contact{
    .btn-submit{
      background: #0C80BB;
      color: #fff;
      border-radius: 0;
    }
  }

}



/*************************
********portfolio CSS*****
**************************/

#portfolio .container-fluid,
#portfolio .col-sm-3  {
  overflow: hidden;
  padding: 0;
}

#portfolio .folio-item {
  position: relative;
  border: 4px solid #fff;
}

#portfolio .overlay {
  background-color: #000;
  color: #fff;
  left: 0;
  right:0;
  bottom:-100%;
  height: 0;
  position: absolute;
  text-align: center;
  opacity:0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.overlay .overlay-content {
  display: table;
  height: 100%;
  width: 100%;
}

.overlay .overlay-text {
  display: table-cell;
  vertical-align: middle;
}

.overlay .folio-info {
  opacity: 0;
  margin-bottom: 75px;
  margin-top: -75px;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.overlay .folio-info h3 {
  margin-top: 0;
  color: #fff;
}

.folio-overview a {
  font-size: 18px;
  color: #333;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: #fff;
  display: inline-block;
  margin-top: 20px;
  margin-right: 5px;
}

.folio-overview a:hover {
  color: #fff;
}

.folio-overview .folio-expand {
  margin-top: -500px;
  margin-left: -500px;
}

.folio-image, .folio-overview .folio-expand {
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}

#portfolio .folio-item:hover .folio-image {
  -webkit-transform: scale(1.5) rotate(-15deg);
  transform: scale(1.5) rotate(-15deg);
}

.folio-image img {
  width: 100%;
}

#portfolio .folio-item:hover .overlay {
  opacity: 0.8;
  bottom: 0;
  height: 100%;
}

#portfolio .folio-item:hover .folio-overview .folio-expand {
  margin-top: 0;
  margin-left:0;
}

#portfolio .folio-item:hover .overlay .folio-info {
  opacity: 1;
  margin-bottom:0;
  margin-top:0;
}


#single-portfolio {
  padding: 90px 0;
  background: #f5f5f5;
  position: relative;
}

#single-portfolio img {
  width: 100%;
  margin-bottom: 10px;
}

#single-portfolio .close-folio-item {
  position: absolute;
  top: 30px;
  font-size: 34px;
  width: 34px;
  height: 34px;
  left: 50%;
  color: #999;
  margin-left: -17px;
}



.carousel-control{
  width: 3%;
  color:black;
}
.carousel-control.left{
   background-image: none !important;
 }
.carousel-control.right{
  background-image: none !important;
}