@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}
@mixin background-position($position) {
  background-position: $position;
}
@mixin transition-all($speed) {
  -webkit-transition: all $speed;
  -moz-transition: all $speed;
  transition: all $speed;
}
@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -ms-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}
@mixin transform-translate-all($transform) {
  -webkit-transform: translate($transform, $transform);
  -moz-transform: translate($transform, $transform);
  -ms-transform: translate($transform, $transform);
  -o-transform: translate($transform, $transform);
  transform: translate($transform, $transform);
}
@mixin transform-translate-X($transform) {
  -webkit-transform: translateX($transform);
  -moz-transform: translateX($transform);
  -ms-transform: translateX($transform);
  -o-transform: translateX($transform);
  transform: translateX($transform);
}
@mixin transform-translate-Y($transform) {
  -webkit-transform: translateY($transform);
  -moz-transform: translateY($transform);
  -ms-transform: translateY($transform);
  -o-transform: translateY($transform);
  transform: translateY($transform);
}
@mixin reset-padding-margin() {
  padding: 0;
  margin: 0;
}
@mixin inline-li() {
  display: inline-block;
  list-style: none;
}
@mixin clear { content: ''; line-height: 0; display: table; clear: both; }