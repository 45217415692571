/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */


$font-base: 				        "MyriadPro", sans-serif;
$font-arabic: 				        "DINNextLTW23", sans-serif;

$color-border: 				        #dcd8d6;

$breakpoint-1199:			1199px;
$breakpoint-991:		    991px;
$breakpoint-768:			768px;
$breakpoint-425:		    425px;

$black-color:                       #000000;
$pale-color:                        #bfa992;