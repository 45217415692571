.programs-text{
  color:#5e5f5e;
  p{
    font-size: 16px;
  }
}

.course-description{
  background: #b3b56a;
  margin: 20px auto;
  padding: 5px 30px 25px 30px;
  text-align: center;

  h2{
    color: #fff;
    font-weight: 500;
    font-size: 22px;
    line-height: 40px;
    border-bottom: 1px solid #78787b;
  }

  img:hover{
    @include transition-all(0.2s ease-in-out);
    opacity:0.7;
  }
}

a{
  .button-library {
    background: #eaa830;
    padding:10px;
    margin: 10px;
    color: #fff;
    width: 150px;
    text-align: center;
    &:hover {
      @include transition-all(0.2s ease-in-out);
      opacity: 0.7;
    }
  }
  .button-library2 {
    background: #eaa830;
    padding:10px;
    margin: 10px;
    color: #fff;
    width: 250px;
    text-align: center;
    &:hover {
      @include transition-all(0.2s ease-in-out);
      opacity: 0.7;
    }
  }

}
