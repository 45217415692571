/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.logo-container {
  text-align: center;
  margin: 40px auto 0;
  padding: 20px 0 0 0;
  border-top: 5px solid #78787B;
  border-bottom: 5px solid #78787B;
  img {
    max-width: 170px;
    float: left ;
     &:lang(ar) {
    float: right !important;
  }
    margin: 0 160px 0 38px;
    @media (max-width: $breakpoint-425) {
      margin: 0 80px 20px 38px;
    }
  }

  p{
    color: black;
    margin: 79px 0 0 49px;
  }

}


.langu {
  float: right !important;
  margin-right: 20px;
  @media (max-width: $breakpoint-768){
    float: left !important;
  }
  img{
    width: 35px;
  }

  ul{
    list-style:none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      padding: 15px 5px;
      text-align: center;

      a {
        color: black;
        font-weight: 300;
        &:hover {
          opacity: 0.6;
          @include transition-all(0.3s);
        }
      }

    }
  }
}


.langu-en{
  float: left !important;
  margin-left: 20px;
  @media (max-width: $breakpoint-768){
    float: right !important;
  }
  img{
    width: 35px;
  }

  ul{
    list-style:none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      padding: 15px 5px;
      text-align: center;

      a {
        color: black;
        font-weight: 300;
        &:hover {
          opacity: 0.6;
          @include transition-all(0.3s);
        }
      }

    }
  }
}


.logo-container-2 {
  text-align: right;
  margin: 40px auto 0;
  padding: 20px 0 0 0;
  border-top: 5px solid #78787B;
  border-bottom: 5px solid #78787B;
  img {
    max-width: 170px;
    padding-bottom: 15px;
  }

}


.langu {
  float: right;
  margin-right: 20px;

  &:lang(ar) {
    float: left;
  }
  img{
    width: 35px;
  }

  ul{
    list-style:none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      padding: 15px 5px;
      text-align: center;

      a {
        color: black;
        font-weight: 300;
        &:hover {
          opacity: 0.6;
          @include transition-all(0.3s);
        }
      }

    }
  }
}

