.page-title{
  margin:30px auto; 
  position: relative;
    &:lang(ar) {
   float: right;
   }
  .title-container{
    position: absolute;
    top: 15px;
    left: 50px;
    right: 50px;
    font-size: 20px;
    color: #fff;
  }

}

.page-content{
  padding:20px 60px;
  p{
   font-size:  18px;
  }
}
.intro-text{
  padding:20px 60px;
  p{
    font-size:  18px;
  }
}

.team-page{
  text-align: center;
  padding: 50px;
  float: right;
  width: 300px;
  height: 350px;

  img.team-image {
    height: 200px;
  }

  .name-team{
    color: #00a8c8;
    padding-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #78787b;
    font-size: 14px;
  }
  .position-team{
    color: #78787b;
  }
}

.team-details{
  .image-details{
    padding: 30px;
    .team-details-object{
      max-width:170px;
    }
    .news-details-object{
    }
    .email-details a, .cell-details a{
      color: #78787b;
      direction: ltr;

    }
  }


  .team-details-body{
    padding: 30px;

     .team-details-heading{
        color: #00a8c8;
        span{
         color:#000;
       }
    }

  }
}

.legal{
  background: #c1c1c1;
  text-align: center;
}


.registerform{
  padding:10px;
  border: 1px solid #EEEEEE;
  img{
    margin: auto;
  }
  h1{
    text-align: center;
    background:#ff9900;
    padding:8px;
    font-size: 26px;
  }
  h2{
    text-align: center;
    background:#ff9900;
    padding:8px;
    font-size: 22px;
    font-weight: 500;
  }
  h3{
    text-align: center;
    font-weight: 400;
    color: #ff9900;

    
  }
  h4{
    text-align: center;
  }

}

.text-right{
  text-align: right !important;
  font-family: serif;
  font-weight: 700 !important;
}

.form-horizontal .form-group {
  margin-right: 0 !important;
  margin-left: -30px !important;
}

.blueh3 {
  text-align: right !important;
  font-size: 18px;
  color: #244a6b !important;
  padding: 10px 0;
}

#videoshow{
  display: none;
  position: relative;
  margin: auto;
}
#foo{
  display: block;
  position: relative;
  margin: auto;
}
#play{
  position: absolute;
  z-index: 9999;
  top: 50px;
  display: block;
  left: 550px;
  right: 550px;
  cursor: pointer;
}
@media (max-width: 768px ) {
  #play{
    left: 100px;
    right:100px;
  }
}