@import "modules/fonts";
@import "modules/variables";
@import "modules/mixins";
@import "modules/helpers";
@import "modules/spacing";


@import "partials/header";
@import "partials/footer";
@import "partials/general";
@import "partials/social";
@import "partials/navigation";
@import "partials/news";
@import "partials/module";
@import "partials/page";
@import "partials/breadcrumb";
@import "partials/calendar";
@import "partials/programs";
@import "partials/gallery";