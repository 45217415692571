.news{
  background: #D3D1D7;
  .part1 {
    background: #D3D1D7;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    h2{
      font-size: 25px;
      text-transform: none;
      color: #404041;
      margin-bottom: 25px;
    }
    .box-news{
      max-height:300px;

      img{
        max-width: 300px;
        max-height: 200px;
      }
      @media (min-width: $breakpoint-425) and (orientation: landscape) {
        max-height:100%;
      }
      .box-news-title a{
        color: #5E5F5E;
        font-size: 18px;
        &:hover{
          color: #000;
          @include transition-all(0.2s ease-in-out);
          text-decoration: underline !important;
        }
      }
      span.news-date{
        display: block;
      }
      &:hover{
        opacity: 0.7;
        @include transition-all(0.2s ease-in-out);
      }
    }
  }
  .part2{
    background: #5E5F5E;
    padding: 20px;
    h2{
    &:lang(ar) {
      padding: 0 40px;
      }
      color: #E7E7E7;
      span{
        color: #F5B330;
        font-size: 15px;
        margin:auto;
        font-weight: 300;
      }
    }
  }

}

.emore {
  margin: 10px auto;
  color: #f5b330;
  font-size: 15px;
  &:hover{
    color: #fff;
  }
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-panel {
  width: calc( 100% - 75px );
  float: right;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  &:lang(ar) {
  float: left;
  }
}

.timeline > li > .timeline-badge {

  color: #fff;
  width: 70px;
  height: 70px;
  line-height: 32px;
  font-size: 32px;
  text-align: center;
  position: absolute;
  top: 16px;
  margin-bottom:10px;
  margin-left: -25px;
  background-color: #F5B330;
  z-index: 100;
    &:lang(ar) {
right: 0;
font-size: 25px;
}

}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}
.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
  color: white;
  font-size: 16px;
}
.timeline-body > p + p {
  margin-top: 5px;
}


.news-content{
  color: #000;
  a{
    color: #000;
  }
   .nav-tabs {

       li{
         .active{
           color: #b3b56a !important;
         }
         &:lang(ar) {
           float: right;
         }

     }


  }


}
.bmore {
  margin:20px auto  ;
  a{
    background: #0C80C3;
    padding:10px 20px;
    color: #ffffff;
    &:hover{
      @include transition-all(0.2s ease-in-out);
      background: #404041;
    }
  }
}

.border-bottom{
  border-bottom: 1px solid #ddd;
  padding:0;
}


.vodeo {
#foo{
  border:1px solid #ddd;
}
}


.newsbutton {
  display: inline-block;

  a{
    color: #fff;
    background: #0e2346;
    border: 0;
    font-size: 16px;
    width: 103px;
    height: 40px;
    padding: 6px;
    text-align: center;
    margin: 10px;
 &:hover {
   background: #e7870d;
}


    &:lang(ar) {
      float: right;
    }
  }

}

.active>a{
  background: #e7870d;
}


