/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

footer {
  font-family: $font-arabic;
  direction: inherit;
  color: #000;
  padding-top: 40px;
  padding-bottom: 20px;
  a{
    color: #000;
  }

  .background-footer{
    background: #F4F5F5;
    margin:20px;
  }
  .logo-container {
    display: inline-block;
    margin-bottom: 25px;
    margin-top: 25px;

    img{
      width: 80%;
    }
  }


  ul {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0 0 4px 0;
    list-style-type: none;


    li {
       margin-left: 10px;
      font-size: 14px;
      line-height: 20px;
       font-weight: 400;
      &:lang(ar) {
        margin-right: 20px;
      }

      @media (max-width: $breakpoint-425) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @media (max-width: 605px) {
        padding-left: 7px;
        padding-right: 7px;
      }

      @media (max-width: 525px) {
        padding-left: 4px;
        padding-right: 4px;
        font-size: 12px;
        line-height: 12px;
      }

      i {
        font-size: 30px !important;
        line-height: 30px !important;
      }
    }

    li li{
      padding-right: 10px;
      &:lang(ar){
        padding-left: 10px;
      }
    }
  }

  .social-media {
    li {
      padding-right: 30px;
      padding-left: 0;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .copyright-message {
    font-family: HelveticaNeueLTStd-Roman;
    font-size: 10px;
    line-height: 10px;
  }
}