nav {
  padding-top: 20px;
  padding-bottom: 13px;
  color: #fff;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    border-bottom: 1px solid grey;
    background-color: #ffffff;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .primary-menu {
    @media (min-width: 992px) {
      display: block !important;
    }
    @media (max-width: $breakpoint-991) {
      display: none;
      left: 0;
      z-index: 11;
      width: 100%;
      background-color: #c6cdb7;
      padding: 20px;
    }
    @media (max-width: $breakpoint-425) {
      top: 74px;
    }
    ul {

      .sub-menu {
        display: none;
      }

      li {
        .active{
          color: #fff;
          background: #78787B;
          text-decoration: none;
        }

        display: inline-block;
        margin: 0;
        padding: 0;
        margin-right: 10px;


        a {
          color:#000;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          background: #C6CDB7;
          padding: 10px;
          width:108px;
          display:block;
          &:lang(en) {
            font-size: 13px;
            width:113px;
          }


          &:hover, &:focus{
            @include transition-all(0.2s ease-in-out);
            color: #fff;
            background: #78787B;
            text-decoration: none;
          }
        }
        @media (max-width: $breakpoint-1199) {
          margin-right: 24px;
        }
        @media (max-width: $breakpoint-991) {
          display: block;
          margin-right: 0;
          margin-bottom: 20px;
        }

        &:hover, &:focus{
          .sub-menu {
            display: block;
            background: #78787B;
            text-align: left;
            padding: 15px 25px 15px 20px;
            min-width: 150px;
            position: absolute;
            z-index: 10;

            @media (max-width: $breakpoint-991) {
              position: inherit;
            }
            ul{
              &:lang(ar) {text-align: right;}
              text-align: left;
              span{
                a{
                border-bottom: 1px dashed #ffffff;
                background: #78787B;
                  display: block;
                  width: 100%;
                  color: #fff;
                  &:hover{
                    opacity: 0.8;
                    background: #c1c1c1;
                  }
              }
              }
              li{
                padding-top: 5px;
                margin: 5px 20px  !important;
                display: block;
                color: #fff;

                a{
                  background: none;
                  width:100%;
                  display: initial;
                  color: #fff;
                  &:hover{
                    opacity: 0.8;
                    background: #c1c1c1;
                    padding-top: 5px;
                  }
                }
              }
            }

          }


        }



      }
    }
  }
  .navigation-search-form-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    border-bottom: 1px solid grey;
    display: none;
    i {
      &.fa-times {
        position: fixed;
        top: 27px;
        left : 15px;
        font-size: 22px;
        line-height: 22px;
        cursor: pointer;
        @media (max-width: $breakpoint-991) {
          top: 39px;
        }
        @media (max-width: $breakpoint-425) {
          top: 27px;
        }
        &:hover {
          @include transition-all(0.2s ease-in-out);
          color: red;
        }
      }
    }
    form {
      input[type=text] {
        width: 100%;
        padding: 23px 20px 23px 20px;
        font-size: 18px;
        line-height: 18px;
        color: #000000;
        font-weight: 400;
        border: 0;
        @media (max-width: $breakpoint-991) {
          padding: 30px 20px 30px 20px;
        }
        @media (max-width: $breakpoint-425) {
          padding: 22px 20px 22px 20px;
        }
        &:focus {
          outline: none !important;
        }
      }
    }
  }
  .mobile-navigation {
    display: none;
    .fa{
      font-size: 30px;
    }
    @media (max-width: $breakpoint-991) {
      display: block;
      margin-top: -80px;
    }
    @media (max-width: $breakpoint-425) {
      img {
        height: auto;
        width: 150px;
      }
    }
    #mobile-menu-toggle, .navigation-search-open {
      font-size: 24px;
      line-height: 24px;
      display: inline-block;
      float: left;
      margin-top: 12px;
      @media (max-width: $breakpoint-425) {
        margin-top: 8px;
      }
    }
    #mobile-menu-toggle {
      float: left;
      &:lang(ar) {
        float: right;
      }
    }
    .navigation-search-open {
      float: right;
    }
  }
}


